(() => {
  const angular = window.angular;

  angular.module('app').config(Router);

  Router.$inject = ['$stateProvider'];

  function Router($stateProvider) {
    $stateProvider.state('member.customer.show.project.bulkStateChange', {
      url: '/bulk-state-change',
      component: 'bulkStateChange',
      authenticated: true,
      resolve: {
        module: [
          '$ocLazyLoad',
          function ($ocLazyLoad) {
            return $ocLazyLoad.load([
              window.assetsPath + '/js/app/bulk-state-change.module.min.js',
            ]);
          },
        ],
      },
      ncyBreadcrumb: {
        label: '{{"entities.tool.__tools.BulkStateChange" | translate}}',
      },
    });
  }
})();
