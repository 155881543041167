(function () {
  const angular = window.angular;

  SensorTypeService.$inject = ['AppUtils', 'SensorType', 'ObjectRecognitionService'];

  angular.module('commonServices').service('SensorTypeService', SensorTypeService);

  function SensorTypeService(utils, SensorType, ObjectRecognitionService) {
    const sensorTypeCacheByType = {};
    const sensorTypeSchemaCacheByType = {};

    const sensorTypeSettings = {
      BeaconTracking: {},
      Boolean: {},
      CargoContainerISOReader: {
        zone: true,
      },
      Checkout: {},
      ColorPresence: {},
      CrossLineMultiRecognition: {
        line: true,
      },
      Debug: {},
      FaceDetection: {
        zone: true,
        upload: true,
      },
      FaceMaskDetection: {
        zone: true,
        upload: true,
      },
      GPS: {},
      GranularityDetection: {
        zone: true,
        thresholds: true,
        upload: true,
      },
      LicensePlate: {
        zone: true,
        upload: true,
      },
      LineCrossingDetection: {
        line: true,
        upload: true,
      },
      MissingHelmetDetection: {
        zone: true,
        upload: true,
      },
      MotionDetection: {
        zone: true,
      },
      MultiZoneObjectTracking: {
        multizone: true,
        upload: true,
      },
      NaiveSocialDistancing: {
        zone: true,
        upload: true,
      },
      NoPlateDetection: {
        zone: true,
        upload: true,
      },
      Number: {
        thresholds: true,
      },
      ObjectCounting: {},
      ObjectRecognition: {
        zone: true,
        upload: true,
      },
      ObjectRecognitionNumeric: {
        zone: true,
        thresholds: true,
        upload: true,
      },
      OpeningDetection: {
        zone: true,
      },
      OverlapDetection: {
        zone: true,
        upload: true,
      },
      PeriodicObjectCounting: {
        zone: true,
      },
      PoseDetection: {
        zone: true,
        upload: true,
      },
      Referrer: {},
      SceneChange: {
        upload: true,
      },
      ShoppingHours: {},
      SpeedChange: {
        grid: true,
        upload: true,
      },
      StoppedLicensePlate: {
        zone: true,
        upload: true,
      },
      StoppedObjectsDetector: {
        zone: true,
        upload: true,
      },
      String: {},
      Video: {
        upload: true,
      },
    };

    return {
      find: find,
      findByType: findByType,
      findById: findById,
      getSchema: getSchema,
      findSchemas,
      getEmptyRuleClausesParametersFromSchema,
      getEmptyRuleClausesParameters: getEmptyRuleClausesParameters,
      getSummaryPaths: getSummaryPaths,
      getZoneAllowedTypes,
      getNumericTypes,
      isNumeric,
      getAllowedClasses,
      getSettings: () => {
        return structuredClone(sensorTypeSettings);
      },
    };

    function getAllowedClasses(sensorTypeLabel) {
      const yoloClasses = ObjectRecognitionService.getClasses();
      return findSchemaByType(sensorTypeLabel, 'sensors').then((schema) => {
        let triggerProperties = schema?.triggers?.items
          ? structuredClone(schema.triggers.items)
          : {};

        triggerProperties =
          triggerProperties.type === 'array' ? triggerProperties.items : triggerProperties;
        triggerProperties = triggerProperties.properties || {};
        if (triggerProperties.classes) {
          triggerProperties.classes = triggerProperties.classes.anyOf
            ? triggerProperties.classes.anyOf.find((current) => !!current.items)
            : triggerProperties.classes;
        }

        if (yoloClasses.length && triggerProperties.classes?.items?.enum) {
          triggerProperties.classes.items.enum = triggerProperties.classes.items.enum
            .filter((c) => {
              return yoloClasses.find((y) => y.name === c);
            })
            .sort();
        }

        return triggerProperties?.classes?.items?.enum || [];
      });
    }

    function findSchemaByType(sensorType, schemaType) {
      if (sensorTypeSchemaCacheByType[sensorType]?.[schemaType]) {
        return utils.Promise.resolve(sensorTypeSchemaCacheByType[sensorType][schemaType]);
      }

      const method = `__findByType__schemas__${schemaType}`;
      return SensorType[method]({
        type: sensorType,
      }).$promise.then((st) => {
        const schema = st.schema;
        sensorTypeSchemaCacheByType[sensorType] = sensorTypeSchemaCacheByType[sensorType] || {};
        sensorTypeSchemaCacheByType[sensorType][schemaType] = schema;
        return schema;
      });
    }

    function findByType(sensorType) {
      if (sensorTypeCacheByType[sensorType]) {
        return utils.Promise.resolve(sensorTypeCacheByType[sensorType]);
      }

      return find({
        where: { type: sensorType },
      }).then((result) => {
        if (!result.length) {
          const err = new Error('Not Found');
          err.code = 'NOT_FOUND';
          err.statusCode = 404;
          throw err;
        }
        sensorTypeCacheByType[sensorType] = result[0];
        return result[0];
      });
    }

    /**
     *
     * @param {LoopbackFilter} filter
     * @returns {Promise<SensorType[]>}
     */
    function find(filter) {
      return SensorType.find({
        filter: filter,
      }).$promise.catch((err) => {
        throw utils.getHTTPError(err);
      });
    }

    /**
     *
     * @param {string} property
     * @param {string|number|boolean} value
     * @param {LoopbackFilter} filter
     * @returns {Promise<SensorType>}
     */
    function findBy(property, value, filter) {
      filter = typeof filter === 'object' ? filter : {};
      filter.where = filter.where || {};
      filter.where[property] = value;

      return find(filter).catch((err) => {
        throw utils.getHTTPError(err);
      });
    }

    /**
     *
     * @param {string} sensorType
     * @param {LoopbackFilter} filter
     * @returns {Promise<SensorType>}
     */
    function findByType(sensorType, filter) {
      return findBy('type', sensorType, filter).then((result) => {
        if (!result.length) {
          const err = new Error('Not Found');
          err.code = 'NOT_FOUND';
          err.statusCode = 404;
          throw err;
        }
        return result[0];
      });
    }

    /**
     *
     * @param {string} sensorTypeId
     * @param {LoopbackFilter} filter
     * @returns {Promise<SensorType>}
     */
    function findById(sensorTypeId, filter) {
      return findBy('id', sensorTypeId, filter).then((result) => {
        if (!result.length) {
          const err = new Error('Not Found');
          err.code = 'NOT_FOUND';
          err.statusCode = 404;
          throw err;
        }
        return result[0];
      });
    }

    /**
     *
     * @param {string} sensorTypeId
     * @param {string} schemaType
     * @returns {Promise<SensorType>}
     */
    function getSchema(sensorTypeId, schemaType) {
      const method = 'prototype$__get__schemas__' + schemaType;
      if (!SensorType[method]) {
        return Promise.reject('Invalid Schema');
      }

      const prepare = {
        sensors: prepareSensorSchema,
      };

      return SensorType[method]({
        id: sensorTypeId,
      })
        .$promise.then((schema) => {
          if (prepare[schemaType]) {
            prepare[schemaType](schema);
          }

          return schema;
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function findSchemas(filter, schemaType) {
      const method = '__find__schemas__' + schemaType;
      if (!SensorType[method]) {
        return Promise.reject('Invalid Schema');
      }

      const prepare = {
        sensors: prepareSensorSchema,
      };

      return SensorType[method]({ filter: filter })
        .$promise.then((schemas) => {
          schemas = schemas.map((current) => {
            current.schema.type = current.type;
            return current.schema;
          });
          if (prepare[schemaType]) {
            for (let schema in schemas) {
              prepare[schemaType](schema);
            }
          }

          schemas.forEach((c) => (c.parameters = c.parameters || {}));
          return schemas;
        })
        .catch((err) => {
          throw utils.getHTTPError(err);
        });
    }

    function prepareSensorSchema(sensorSchema) {
      let triggers;

      if (sensorSchema?.triggers?.items) {
        triggers = sensorSchema.triggers.items;
      }

      if (sensorSchema?.triggers?.anyOf) {
        triggers = sensorSchema.triggers.anyOf.find(
          (c) => !c.enum || JSON.stringify(c.enum) === `["null"]`
        );
      }

      triggers = triggers || {};
      triggers = triggers.type === 'array' ? triggers.items : triggers;
      triggers = triggers.properties || {};

      if (triggers.classes && triggers.classes.items && triggers.classes.items.enum) {
        const classes = utils.arrayToObject(ObjectRecognitionService.getClasses(), 'value');
        triggers.classes.items.enum = (triggers.classes.items.enum || []).filter(
          (current) => !!classes[current.toLowerCase()]
        );
      }
    }

    function getEmptyRuleClausesParametersFromSchema(schema, type) {
      if (type && schema[type]) {
        schema = schema[type];
      }
      if (schema.anyOf) {
        schema = schema.anyOf.find((c) => !c.enum || JSON.stringify(c.enum) === `["null"]`);
      }

      switch (schema.type) {
        case 'object': {
          const obj = {};

          for (let key in schema.properties) {
            obj[key] = getEmptyRuleClausesParametersFromSchema(schema.properties[key]);
          }
          return obj;
        }
        case 'string': {
          return '';
        }
        case 'number': {
          /* if (schema.minimum) {
            return schema.minimum;
          }

          if (schema.maximum) {
            return schema.maximum;
          } */

          return 0;
        }
      }
    }

    function getZoneAllowedTypes() {
      return Object.keys(sensorTypeSettings).filter((current) => {
        return sensorTypeSettings[current].zone;
      });
    }

    function getNumericTypes() {
      return Object.keys(sensorTypeSettings).filter((current) => {
        return sensorTypeSettings[current].thresholds;
      });
    }

    function isNumeric(sensorType) {
      return getNumericTypes().indexOf(sensorType) !== -1;
    }

    function getEmptyRuleClausesParameters(sensorType) {
      let parameters = null;

      switch (sensorType) {
        case 'LineCrossingDetection':
        case 'NaiveSocialDistancing':
        case 'ObjectRecognition':
        case 'StoppedObjectsDetector':
        case 'SpeedChange':
          const classes = ObjectRecognitionService.getClasses().filter(
            (current) => !current.disabled
          );
          parameters = {};
          parameters.byClass = {};

          if (sensorType === 'NaiveSocialDistancing') {
            parameters.byClass.person = { thresholds: { minProbability: 0 } };
          } else {
            classes.forEach((_class) => {
              parameters.byClass[_class.value] = { thresholds: { minProbability: 0 } };
            });
          }
          break;
        case 'PoseDetection':
          const poses = ObjectRecognitionService.getPoses(true);
          parameters = {};
          parameters.byPose = {};

          poses.forEach((_pose) => {
            parameters.byPose[_pose.upper] = { thresholds: { minProbability: 0 } };
          });
          break;
        case 'FaceDetection':
          parameters = {};
          parameters.thresholds = { minProbability: 0 };
          break;
        case 'FaceMaskDetection':
          parameters = {};
          parameters.thresholds = { faces: { minProbability: 0 }, masks: { minProbability: 0 } };
          break;
        case 'LicensePlate':
      }

      return parameters;
    }

    function getSummaryPaths(type) {
      const classes = ObjectRecognitionService.getClasses();

      const stats = { count: null, sum: null, avg: null, min: null, max: null };

      let detectionStats = {
        length: Object.assign({}, stats),
        probability: Object.assign({}, stats),
        class: {},
      };

      let paths = {
        content: Object.assign({}, stats),
        location: Object.assign({}, stats),
      };

      switch (type) {
        case 'Number':
          return paths;
        case 'ObjectCounting':
        case 'PeriodicObjectCounting':
          paths.content = {
            length: Object.assign({}, stats),
            class: {},
          };
          classes.forEach((_class) => {
            paths.content.class[_class.value] = { length: Object.assign({}, stats) };
          });
          return paths;
        case 'LineCrossingDetection':
        case 'ObjectRecognition':
        case 'StoppedObjectsDetector':
        case 'SpeedChange':
          paths.content = {
            detections: JSON.parse(JSON.stringify(detectionStats)),
            files: { image: { length: Object.assign({}, stats) } },
          };
          classes.forEach((_class) => {
            paths.content.detections.class[_class.value] = {
              length: Object.assign({}, stats),
              probability: Object.assign({}, stats),
            };
          });
          return paths;
        case 'ObjectRecognitionNumeric':
        case 'GranularityDetection':
          const numericDetectionStats = structuredClone(detectionStats);
          numericDetectionStats.value = Object.assign({}, stats);

          paths.content = {
            detections: numericDetectionStats,
            files: { image: { length: Object.assign({}, stats) } },
          };
          classes.forEach((_class) => {
            paths.content.detections.class[_class.value] = {
              length: Object.assign({}, stats),
              probability: Object.assign({}, stats),
              value: Object.assign({}, stats),
            };
          });
          return paths;
        case 'NaiveSocialDistancing':
          paths.content = {
            length: Object.assign({}, Object.assign({}, stats)),
            files: { image: { length: Object.assign({}, stats) } },
            detections: JSON.parse(JSON.stringify(detectionStats)),
            pairs: { length: Object.assign({}, stats) },
          };
          classes.forEach((_class) => {
            paths.content.detections.class[_class.value] = {
              length: Object.assign({}, stats),
              probability: Object.assign({}, stats),
            };
          });
          return paths;
        case 'CrossLineMultiRecognition':
          paths.content = {
            incoming: Object.assign({}, stats),
            outgoing: Object.assign({}, stats),
            class: {},
          };
          classes.forEach((_class) => {
            paths.content.class[_class.value] = {
              incoming: Object.assign({}, stats),
              outgoing: Object.assign({}, stats),
            };
          });

          return paths;
        case 'PoseDetection':
          const poses = ObjectRecognitionService.getPoses();
          paths.content = {
            files: { image: { length: Object.assign({}, stats) } },
            detections: {
              length: Object.assign({}, stats),
              probability: Object.assign({}, stats),
              label: {},
            },
          };

          poses.forEach((_class) => {
            paths.content.detections.label[_class.value.toUpperCase()] = {
              length: Object.assign({}, stats),
            };
          });
          return paths;
        case 'FaceDetection':
          paths.content = {
            files: { image: { length: Object.assign({}, stats) } },
            detections: {
              length: Object.assign({}, stats),
              probability: Object.assign({}, stats),
            },
          };
          return paths;
        case 'FaceMaskDetection':
          paths.content = {
            files: { image: { length: Object.assign({}, stats) } },
            detections: {
              length: Object.assign({}, stats),
              probability: Object.assign({}, stats),
              mask: { probability: Object.assign({}, stats) },
            },
          };
          return paths;
        case 'StoppedLicensePlate':
          paths.content = {
            files: { image: { length: Object.assign({}, stats) } },
            detections: {
              length: Object.assign({}, stats),
              stopped: {},
            },
          };

          classes.forEach((_class) => {
            paths.content.detections.stopped[_class.value] = Object.assign({}, stats);
          });
          return paths;
      }

      return [];
    }
  }
})();
