/**
 * Created by reneseses on 04-04-17.
 */
(function () {
  'use strict';

  let angular = window.angular;
  let vm;

  EmbeddedContainerController.$inject = ['$scope', '$element', '$timeout', '$state', 'AppUtils'];

  angular.module('app').component('embeddedContainer', {
    template:'<div class="panel panel-default" ng-if="!ctrl.hideHeader"><header class="panel-heading text-center"><h2><strong>{{ctrl.options.name}}</strong></h2><div class="panel-toolbars pull-right"><div class="panel-toolbar"><div class="btn-group" uib-dropdown><button ng-disabled="ctrl.loading" class="btn btn-xs btn-primary" uib-dropdown-toggle><span class="fas fa-cog"></span> <span translate>general.actions</span> <span class="caret"></span></button><ul class="dropdown-menu dropdown-menu-right" uib-dropdown-menu><li><a href="#" ng-click="ctrl.reload()"><span><i class="fas fa-sync marginR5"></i> <span translate>buttons.reload</span></span></a></li><li><a href="#" ng-click="ctrl.showReportForm()"><span><i class="fas fa-edit marginR5"></i> <span translate>buttons.edit</span></span></a></li><li class="divider"></li><li><a href="#" class="text-danger" ng-click="ctrl.removeReport()"><span class="text-danger"><i class="fas fa-trash marginR5"></i> <span translate>buttons.delete</span></span></a></li></ul></div></div></div></header><div class="panel-body"><iframe class="embedded-frame" style="width: 100%; height: 100%; margin: auto" ng-src="{{ctrl.options.trustSrc}}" frameborder="0" allowfullscreen></iframe><spinner show="ctrl.re" css-class="\'body-bg small\'" show-logo="true"></spinner></div></div><div ng-if="ctrl.hideHeader" class="absolute"><iframe class="embedded-frame" allow="clipboard-write; fullscreen" style="width: 100%; height: 100%; margin: auto" ng-src="{{ctrl.options.trustSrc}}" frameborder="0" allowfullscreen></iframe><spinner show="ctrl.loading" css-class="\'body-bg small\'" show-logo="true"></spinner></div>',
    controller: EmbeddedContainerController,
    controllerAs: 'ctrl',
    bindings: {
      options: '<',
      removeReport: '&',
      showReportForm: '&',
      hideHeader: '<',
      onMessage: '&',
    },
  });

  function EmbeddedContainerController($scope, element, $timeout, $state, utils) {
    vm = this;

    vm.$onInit = function () {
      vm.loading = true;
      vm.childId = utils.generateUUID();

      vm.projectRoot = $state.get().find((c) => c.projectRoot === true);

      $timeout(() => {
        vm.embeddedFrame = element.find('.embedded-frame');
        vm.embeddedFrame.id = vm.childId;
        vm.embeddedFrame.on('load', () => {
          $timeout(() => {
            vm.options.loaded = true;
            vm.loading = false;
          });

          // Se agrega un listener para recibir mensajes desde el iframe
          window.addEventListener('message', onMessage, false);

          // Se agrega un listener para enviar mensajes al iframe desde el padre
          vm.offPostMessage = $scope.$on('postMessage', (event, message) => {
            postMessage(message);
          });

          $timeout(() => {
            postMessage({
              type: 'init',
              payload: {
                id: vm.childId,
              },
            });
          }, 100);
        });

        $timeout(() => {
          vm.options.loaded = true;
          vm.loading = false;
        }, 2000);
      }, 100);
    };

    vm.$onDestroy = function () {
      if (vm.offPostMessage) {
        vm.offPostMessage();
      }
      window.removeEventListener('message', onMessage, false);
    };

    vm.reload = function () {
      vm.embeddedFrame[0].src = vm.embeddedFrame[0].src;
    };

    function postMessage(message) {
      if (!vm.embeddedFrame?.length) {
        return;
      }
      vm.embeddedFrame[0].contentWindow.postMessage(message, '*');
    }

    function onMessage(event) {
      const data = event.data;
      const type = data.type;
      const source = data.source;
      const payload = data.payload;

      if (!type) {
        return;
      }

      if (!source || source !== vm.childId) {
        console.error('Invalid source');
        return;
      }

      switch (type) {
        case 'reload':
          vm.reload();
          break;
        case 'goTo':
          const state = vm.projectRoot.name + '.' + payload.state;
          $state.go(state, payload.params);
          vm.onMessage({ type, payload });
          break;
        default:
          vm.onMessage({ type, payload });
      }
    }
  }
})();
